// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Font Awesome
// $fa-font-path:"~@fortawesome/fontawesome-free/webfonts";
// @import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "~@fortawesome/fontawesome-free/scss/solid.scss";
// @import "~@fortawesome/fontawesome-free/scss/regular.scss";
// @import "~@fortawesome/fontawesome-free/scss/brands.scss";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
